<template>
	<div>
		<div class="back">
			<div class="butbox">
				<div class="name" @click="thatperson">个人用户</div>
				<div class="name2" @click="group">团体用户</div>
			</div>
		</div>
		<div class="inptbox">
			<div class="imgbox">
				<img src="./image/briefsj.png" alt="" :style="{visibility: person ? 'inherit':'hidden'}">
				<img src="./image/briefsj.png" alt="" :style="{visibility: !person ? 'inherit':'hidden'}">
			</div>
			<div class="zhudi">
				<p :style="{visibility: person ? 'inherit':'hidden'}">账号登录</p>
				<div class="inputxx">
					<img src="./image/admin.png" alt="">
					<el-input placeholder="请输入手机号" class="custom-input" v-model="username"></el-input>
				</div>
				<div class="inputxx">
					<img src="./image/pas.png" alt="" style="height: 17PX;width: 13PX;margin-right: 4PX;">
					<el-input placeholder="请输入登录密码" show-password class="custom-input" v-model="password"></el-input>
				</div>
				<div class="but">
					<div class="name">
						<a href="/h5/changePassword">找回密码</a>
					</div>
					<div class="name">
						<a href="/h5/register">注册账号</a>
					</div>
				</div>
				<div class="dl" @click="logon">
					登 录
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				person: true,
				username: '',
				password: '',
				homeDate: {},
				keyword: '',
				userType:'user_type_01'
			}
		},
		created() {
			if(this.$route.query.person === 'true'){
				this.person = true
			}
			if(this.$route.query.person === 'false'){
				this.person = false
				this.userType = 'user_type_02'
			}
			this.$getwxsdk('登录' + '-北京市体育竞赛管理和国际交流中心')
		},
		methods: {
			thatperson() {
				this.person = true
				this.userType = 'user_type_01'
			},
			group() {
				this.person = false
				this.userType = 'user_type_02'
			},
			logon() {
				if (this.username == '') {
					return this.$message.error('请输入账号')
				}
				if (this.password == '') {
					return this.$message.error('请输入密码')
				}
				let data = {
					username: this.$encrypt(this.username),
					password: this.$encrypt(this.password),
					userType: this.$encrypt(this.userType)
				}
				this.$api.checkLogin(data).then(res => {
					let data = {
						res:res.data.data.result,
						index:1
					}
					this.$store.commit("SET_TOKEN",data)
					this.username = ''
					this.password = ''
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.back {
		width: 100%;
		height: 373PX;
		background: url('./image/login.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.butbox {
			display: flex;
			justify-content: center;
			padding-top: 293PX;

			.name {
				font-size: 19PX;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 2PX;
			}

			.name2 {
				font-size: 19PX;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 2PX;
				margin-left: 59PX;
			}
		}
	}

	.inptbox {
		margin-top: -55PX;

		.imgbox {
			display: flex;

			img {
				width: 20PX;
				height: 15PX;
			}

			img:nth-child(1) {
				margin-left: 30%;
			}

			img:nth-child(2) {
				margin-left: 30%;
			}
		}

		.zhudi {
			background: #FFFFFF;
			border-radius: 21PX;

			p {
				font-size: 14PX;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #0576F0;
				padding-top: 36PX;
				margin-left: 62PX;
			}

			.inputxx {
				display: flex;
				align-items: center;
				margin-top: 34PX;
				margin-left: 62PX;
				margin-right: 62PX;
				border-bottom: 1PX solid #AFBACA;

				img {
					width: 17PX;
					height: 14PX;
				}

				::v-deep .el-input__inner {
					border: none;
				}

				.custom-input ::placeholder {
					color: #646566;
					/* 设置提示文字的颜色 */
				}
			}

			.but {
				display: flex;
				margin: 26PX 62PX 0;
				justify-content: space-between;

				.name {
					font-size: 11PX;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #4E668A;
					opacity: 0.72;
				}
				a{
					font-size: 11PX;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #4E668A;
					opacity: 0.72;
				}
			}

			.dl {
				height: 51PX;
				margin: 46PX 39PX;
				width: calc(100% - 78PX);
				background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
				border-radius: 50PX;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 17PX;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #FFFFFF;
			}
		}
	}
</style>